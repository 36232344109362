import React from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as S from './styles';

const Brands = () => {
  const images = [
    { img: '1.jpg' },
    { img: '2.png' },
    { img: '3.png' },
    { img: '4.png' },
    { img: '5.png' },
    { img: '6.png' },
    { img: '7.jpg' },
  ];

  return (
    <S.Brands>
      <Swiper
        spaceBetween={20}
        slidesPerView='auto'
        loop={true}
        breakpoints={{
          // when window width is >= 640px
          100: {
            slidesPerView: 3,
          },
          700: {
            slidesPerView: 4,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 5,
          },
          1250: {
            slidesPerView: 6,
          },
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}>
        {images.map((elem, i) => (
          <SwiperSlide key={i}>
            <div>
              <img src={`/images/logos/${elem.img}`} alt='img' />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.Brands>
  );
};

export default Brands;
