import * as S from './Asorty/styles';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <section
      className='u-clearfix u-image u-shading u-section-1'
      id='sec-0d9b'
      data-image-width={1280}
      data-image-height={820}
      style={{ marginTop: '71px' }}>
      <S.Hero>
        <div className='u-clearfix u-sheet u-sheet-1'>
          <div
            className='u-clearfix u-group-elements u-group-elements-1'
            data-animation-name='customAnimationIn'
            data-animation-duration={1000}>
            <h1 className='u-align-left u-custom-font u-font-montserrat u-text u-text-body-alt-color u-text-1'>
              {t('bucurate')}&nbsp;
              <span className='u-text-custom-color-2'>
                {t('cleanest')}
                <span style={{ fontWeight: 400 }} />
              </span>
            </h1>
            <p className='u-align-left u-custom-font u-font-raleway u-text u-text-2'>
              {t('short_desc')}
            </p>
            <a
              href='#produse'
              className='u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-custom-font u-font-raleway u-radius-6 u-btn-1'>
              {t('more')}
            </a>
          </div>
          <div className='u-expanded-width u-list u-list-1'>
            <div className='u-repeater u-repeater-1' />
          </div>
        </div>
      </S.Hero>
    </section>
  );
};

export default Hero;
