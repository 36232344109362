import * as S from './Asorty/styles';
import { useTranslation } from 'react-i18next';

const Despre = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <>
      <section className='u-clearfix u-section-10' id='sec-b22e'>
        <div
          id='despre-noi'
          style={{ position: 'absolute', top: '-70px' }}></div>
        <div className='u-clearfix u-sheet u-valign-middle u-sheet-1'>
          <S.Despre>
            <h2 className='u-custom-font u-font-montserrat u-text u-text-default u-text-1'>
              {t('despre_firma')}
            </h2>
            <div className='u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1'>
              <div className='u-layout' style={{}}>
                <div className='u-layout-row' style={{}}>
                  <div
                    className='u-align-left u-container-style u-layout-cell u-left-cell u-size-30 u-size-xs-60 u-layout-cell-1'
                    src>
                    <div className='u-container-layout u-valign-top u-container-layout-1'>
                      <p className='u-custom-font u-font-montserrat u-text u-text-2'>
                        {t('ofera_clientilor')}
                        <br />
                        <br />
                        {t('datorita_cooperarii')}

                        <br />
                        <br />
                        {t('energia_generata')}
                      </p>
                    </div>
                  </div>
                  <div
                    className='u-align-center u-container-style u-image u-layout-cell u-right-cell u-size-30 u-size-xs-60 u-image-1'
                    src
                    data-image-width={660}
                    data-image-height={371}
                    data-animation-name='customAnimationIn'
                    data-animation-duration={1000}>
                    <div
                      className='u-container-layout u-valign-middle u-container-layout-2'
                      src
                    />
                  </div>
                </div>
              </div>
            </div>
          </S.Despre>
        </div>
      </section>
      <section className='u-clearfix u-section-11' id='carousel_6445'>
        <div className='u-clearfix u-sheet u-sheet-1'>
          <div className='u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1'>
            <div className='u-layout' style={{}}>
              <div className='u-layout-row' style={{}}>
                <div
                  className='u-align-center u-container-style u-image u-layout-cell u-right-cell u-size-30 u-size-xs-60 u-image-1'
                  src
                  data-image-width={1200}
                  data-image-height={628}
                  data-animation-name='customAnimationIn'
                  data-animation-duration={1000}>
                  <div
                    className='u-container-layout u-valign-middle u-container-layout-1'
                    src
                  />
                </div>
                <div
                  className='u-container-style u-layout-cell u-left-cell u-size-30 u-size-xs-60 u-layout-cell-2'
                  src>
                  <div className='u-container-layout u-valign-middle u-container-layout-2'>
                    <h4 className='u-align-center u-custom-font u-font-montserrat u-text u-text-default mytext u-text-grey-60 u-text-1'>
                      {t('activam_de_multi_ani')}
                      <span className='u-text-custom-color-2'> 3MWp </span>{' '}
                      {t('panouri_solare')}
                    </h4>
                    <p className='u-align-left u-custom-font u-font-montserrat u-text u-text-default u-text-2'>
                      {t('la_retea')}
                    </p>
                    <ul className='u-align-left u-custom-font u-custom-list u-font-montserrat u-text u-text-default u-text-3'>
                      <li>
                        <div className='u-list-icon'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            version='1.1'
                            xmlSpace='preserve'
                            className='u-svg-content'
                            viewBox='0 0 512 512'
                            id='svg-aed7'>
                            <path
                              d='m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0'
                              fill='#2196f3'
                            />
                            <path
                              d='m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0'
                              fill='#fafafa'
                            />
                          </svg>
                        </div>
                        {t('zi_la_zi')}
                      </li>
                      <li>
                        <div className='u-list-icon'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            version='1.1'
                            xmlSpace='preserve'
                            className='u-svg-content'
                            viewBox='0 0 512 512'
                            id='svg-aed7'>
                            <path
                              d='m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0'
                              fill='#2196f3'
                            />
                            <path
                              d='m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0'
                              fill='#fafafa'
                            />
                          </svg>
                        </div>
                        {t('transferarea_surplusului')}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Despre;
