import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Why from './components/Why';
import PanelPrincipal from './components/PanelPrincipal';
import Castiguri from './components/Castiguri';
import Invertor from './components/Invertor';
import Montare from './components/Montare';
import Servicii from './components/Servicii';
import Despre from './components/Despre';
import DeCeNoi from './components/DeCeNoi';
import Contacteaza from './components/Contacteaza';
import Footer from './components/Footer';
import Brands from './components/Brands';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/bundle';
import Gallery from './components/Gallery';
import Asorty from './components/Asorty';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function App() {
  const { t, i18n } = useTranslation(['translation']);
  const [showModal, setShowModal] = useState(false);
  console.log(showModal);
  return (
    <Suspense fallback='loading'>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <body
        data-home-page='Home.html'
        data-home-page-title='Home'
        class='u-body u-xl-mode'
        data-lang='en'>
        <Navbar />
        <Hero />
        <Brands />
        <Why />
        <PanelPrincipal setShowModal={setShowModal} />
        <Castiguri />
        <Invertor />
        <Montare />
        <Gallery />
        <Servicii />
        <Asorty />
        <DeCeNoi />
        <Despre />
        <Contacteaza />
        <Footer />

        {showModal && (
          <section className='u-align-center u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-valign-middle u-dialog-section-15 u-dialog-open'>
            <div className='u-align-center u-container-style u-dialog u-white u-dialog-1'>
              <div className='u-container-layout u-container-layout-1'>
                <h1 className='u-align-center u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-default u-text-1'>
                  {t('rentabilitatea_panourilor')}
                </h1>
                <ul className='u-align-left u-custom-font u-font-montserrat u-text u-text-2'>
                  <li>{t('randament_conversie')}</li>
                  <li>{t('toleranta_pozitiva')}</li>
                  <li>{t('degradare')}</li>
                  <li>{t('reducerea_riscului')}</li>
                  <li>{t('perderi_rezistive')}</li>
                  <li>{t('rezistenta_excelebta')}</li>
                </ul>
                <img
                  className='u-image u-image-contain u-image-default u-image-1'
                  src='images/Longi-Solar-Manufacturer-310W-370W-380W-Perc-Mono-Solar-Panel.jpg'
                  alt=''
                  data-image-width={343}
                  data-image-height={619}
                />
                <h1 className='u-align-center u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-default u-text-3'>
                  {t('performanta_panourilor')}
                </h1>
                <ul className='u-align-left u-custom-font u-font-montserrat u-text u-text-4'>
                  <li>{t('testele_pulverizare')}</li>
                  <li>{t('dimensiune_diferita')}</li>
                  <li>{t('garantie_produse_10_ani')}</li>
                  <li>{t('rezistenta_mpa')}</li>
                  <li>{t('degradare_PID')}</li>
                  <li>{t('cea_mai_buna_performanta')}</li>
                  <li>{t('temperaturi_40_85')}</li>
                </ul>
                <p className='u-align-left u-custom-font u-font-raleway u-text u-text-5'>
                  {t('pentru_mai_multe_detalii')}
                </p>
                <a
                  href='/e7f30d22-1ca5-433a-bcff-c476f736ac48.pdf'
                  className='u-border-1 u-border-active-palette-2-base u-border-hover-palette-1-base u-border-no-left u-border-no-right u-border-no-top u-btn u-button-style u-none u-text-custom-color-3 u-btn-1'
                  target='_blank'>
                  {t('aici')}
                </a>
              </div>
              <button
                className='u-dialog-close-button u-icon u-text-grey-50 u-icon-1'
                onClick={() => setShowModal(false)}>
                <svg
                  className='u-svg-link'
                  preserveAspectRatio='xMidYMin slice'
                  viewBox='0 0 413.348 413.348'
                  style={{}}>
                  <use
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    xlinkHref='#svg-5801'
                  />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  version='1.1'
                  xmlSpace='preserve'
                  className='u-svg-content'
                  viewBox='0 0 413.348 413.348'
                  id='svg-5801'>
                  <path d='m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z' />
                </svg>
              </button>
            </div>
          </section>
        )}
      </body>
    </Suspense>
  );
}

export default App;
