import * as S from './Asorty/styles';
import { useTranslation } from 'react-i18next';

const DeCeNoi = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <section
      className='skrollable u-align-center u-clearfix u-image u-parallax u-shading u-section-9'
      src
      data-image-width={1280}
      data-image-height={853}
      id='sec-305d'>
      <div id='de-ce-noi' style={{ position: 'absolute', top: '-100px' }}></div>
      <S.DeCeNoi>
        <div className='u-clearfix u-sheet u-sheet-1'>
          <h1 className='u-custom-font u-font-montserrat u-text u-text-default u-title u-text-1'>
            {t('de_ce_noi')}
          </h1>
          <p className='u-large-text u-text u-text-variant u-text-2'>
            {t('puteti_castiga')}
          </p>
          <ul className='u-align-left u-custom-font u-custom-list u-font-montserrat u-text u-text-3'>
            <li>
              <div className='u-list-icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  version='1.1'
                  xmlSpace='preserve'
                  className='u-svg-content'
                  viewBox='0 0 512 512'
                  id='svg-3eb4'>
                  <path
                    d='m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0'
                    fill='#2196f3'
                  />
                  <path
                    d='m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0'
                    fill='#fafafa'
                  />
                </svg>
              </div>
              {t('reduceti_facturile')}
            </li>
            <li>
              <div className='u-list-icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  version='1.1'
                  xmlSpace='preserve'
                  className='u-svg-content'
                  viewBox='0 0 512 512'
                  id='svg-3eb4'>
                  <path
                    d='m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0'
                    fill='#2196f3'
                  />
                  <path
                    d='m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0'
                    fill='#fafafa'
                  />
                </svg>
              </div>
              {t('cresterea_tarifelor')}
            </li>
            <li>
              <div className='u-list-icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  version='1.1'
                  xmlSpace='preserve'
                  className='u-svg-content'
                  viewBox='0 0 512 512'
                  id='svg-3eb4'>
                  <path
                    d='m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0'
                    fill='#2196f3'
                  />
                  <path
                    d='m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0'
                    fill='#fafafa'
                  />
                </svg>
              </div>
              {t('restrictii_consumul_de_energie')}
            </li>
          </ul>
          <ul className='u-align-left u-custom-font u-custom-list u-font-montserrat u-text u-text-4'>
            <li>
              <div className='u-list-icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  version='1.1'
                  xmlSpace='preserve'
                  className='u-svg-content'
                  viewBox='0 0 512 512'
                  id='svg-3eb4'>
                  <path
                    d='m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0'
                    fill='#2196f3'
                  />
                  <path
                    d='m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0'
                    fill='#fafafa'
                  />
                </svg>
              </div>
              {t('posibilitatea_utilizarii')}
            </li>
            <li>
              <div className='u-list-icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  version='1.1'
                  xmlSpace='preserve'
                  className='u-svg-content'
                  viewBox='0 0 512 512'
                  id='svg-3eb4'>
                  <path
                    d='m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0'
                    fill='#2196f3'
                  />
                  <path
                    d='m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0'
                    fill='#fafafa'
                  />
                </svg>
              </div>
              {t('venituri_suplimentare')}
            </li>
            <li>
              <div className='u-list-icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  version='1.1'
                  xmlSpace='preserve'
                  className='u-svg-content'
                  viewBox='0 0 512 512'
                  id='svg-3eb4'>
                  <path
                    d='m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0'
                    fill='#2196f3'
                  />
                  <path
                    d='m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0'
                    fill='#fafafa'
                  />
                </svg>
              </div>
              {t('cele_mai_bune')}
            </li>
            <li>
              <div className='u-list-icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  version='1.1'
                  xmlSpace='preserve'
                  className='u-svg-content'
                  viewBox='0 0 512 512'
                  id='svg-3eb4'>
                  <path
                    d='m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0'
                    fill='#2196f3'
                  />
                  <path
                    d='m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0'
                    fill='#fafafa'
                  />
                </svg>
              </div>
              {t('depinzi_de_nimeni')}
            </li>
          </ul>
          <div
            className='u-border-3 u-border-grey-40 u-container-style u-group u-group-1'
            data-animation-name='customAnimationIn'
            data-animation-duration={1000}>
            <div className='u-container-layout u-container-layout-1'>
              <h1 className='u-align-center free_consultation u-custom-font u-font-montserrat u-text u-text-custom-color-3 u-text-default u-text-5'>
                {t('consultare_gratuita')}
              </h1>
              <ul className='u-align-left my_list u-custom-font u-font-montserrat u-text u-text-default u-text-6'>
                <li>{t('importarea_echipamentelor')}</li>
                <li>{t('privind_schemele_de_sprijin')}</li>
                <li>{t('subventii_alocate')}</li>
                <li>{t('privind_mecanismele')}</li>
              </ul>
              <a
                href='#contactați-ne'
                className='u-border-1 u-border-active-palette-2-base u-border-hover-palette-1-base u-border-no-left u-border-no-right u-border-no-top u-btn u-button-style u-none u-text-custom-color-3 u-btn-1'>
                {t('contact-us-link')}
              </a>
            </div>
          </div>
        </div>
      </S.DeCeNoi>
    </section>
  );
};

export default DeCeNoi;
