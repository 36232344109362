import styled from 'styled-components';

export const Brands = styled.div`
  width: 100%;
  max-width: 1289px;
  margin: 0 auto -30px auto;
  max-height: 180px;
  @media (max-width: 768px) {
    padding: 0 30px;
    box-sizing: border-box;
  }
  .swiper-slide {
    div {
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 150px;
        @media (max-width: 768px) {
          max-width: 100px;
        }
        @media (max-width: 500px) {
          max-width: 80px;
        }
      }
    }
  }
`;
