const Footer = () => (
  <footer
    className='u-align-center u-clearfix u-footer u-grey-80 u-footer'
    id='sec-1e6e'>
    <div className='u-clearfix u-sheet u-valign-middle u-sheet-1'>
      <h3 className='u-headline u-text u-text-default u-text-1'>
        <a href='/' className='footer_link'>
          GrandSolar SRL. all rights reserved - 2022
        </a>
      </h3>
    </div>
  </footer>
);
export default Footer;
