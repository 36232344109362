import * as S from './Asorty/styles';
import { useTranslation } from 'react-i18next';

const Why = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <>
      <section className='u-clearfix u-section-2' id='sec-1380'>
        <S.Why>
          <div className='u-clearfix u-sheet u-valign-middle u-sheet-1'>
            <div className='u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1'>
              <div className='u-gutter-0 u-layout'>
                <div className='u-layout-row' style={{}}>
                  <div className='u-size-30 u-size-60-md' style={{}}>
                    <div className='u-layout-row' style={{}}>
                      <div
                        className='u-align-left u-container-style u-image u-layout-cell u-left-cell u-size-60 u-image-1'
                        src
                        data-image-width={1280}
                        data-image-height={853}
                        data-animation-name='customAnimationIn'
                        data-animation-duration={1000}>
                        <div
                          className='u-container-layout u-valign-middle u-container-layout-1'
                          src
                        />
                      </div>
                    </div>
                  </div>
                  <div className='u-size-30 u-size-60-md' style={{}}>
                    <div className='u-layout-col' style={{}}>
                      <div className='u-align-left u-container-style u-layout-cell u-right-cell u-size-60 u-white u-layout-cell-2'>
                        <div className='u-container-layout u-valign-top u-container-layout-2'>
                          <h2
                            className='u-text u-text-1'
                            data-animation-name='customAnimationIn'
                            data-animation-duration={1000}
                            data-animation-direction>
                            {t('de_ce_merita')}
                          </h2>
                          <h5
                            className='u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-default u-text-2'
                            data-animation-name='customAnimationIn'
                            data-animation-duration={1000}
                            data-animation-direction>
                            {t('energie_ieftina')}
                          </h5>
                          <p
                            className='u-custom-font u-font-montserrat u-text u-text-3'
                            data-animation-name='customAnimationIn'
                            data-animation-duration={1000}
                            data-animation-direction>
                            {t('tarif_creste')}
                          </p>
                          <h5
                            className='u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-default u-text-4'
                            data-animation-name='customAnimationIn'
                            data-animation-duration={1000}
                            data-animation-direction>
                            {t('independenta_electrica')}
                          </h5>
                          <p
                            className='u-custom-font u-font-montserrat u-text u-text-5'
                            data-animation-name='customAnimationIn'
                            data-animation-duration={1000}
                            data-animation-direction>
                            {t('independenta_descriere')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </S.Why>
      </section>
      <section className='u-clearfix u-section-3' id='carousel_c535'>
        <S.Why>
          <div className='u-clearfix u-sheet u-valign-middle u-sheet-1 new_shit'>
            <div className='u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1'>
              <div className='u-gutter-0 u-layout'>
                <div className='u-layout-row new-row' style={{}}>
                  <div className='u-size-30 u-size-60-md' style={{}}>
                    <div className='u-layout-col' style={{}}>
                      <div className='u-align-left u-container-style u-layout-cell u-right-cell u-size-60 u-white u-layout-cell-1'>
                        <div className='u-container-layout u-container-layout-1'>
                          <h5
                            className='u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-default u-text-1 text-no-margin'
                            data-animation-name='customAnimationIn'
                            data-animation-duration={1000}>
                            {t('utilizarea_spatiilor')}
                          </h5>
                          <p
                            className='u-custom-font u-font-montserrat u-text u-text-2'
                            data-animation-name='customAnimationIn'
                            data-animation-duration={1000}>
                            {t('acoperisul')}
                          </p>
                          <h5
                            className='u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-default u-text-3'
                            data-animation-name='customAnimationIn'
                            data-animation-duration={1000}>
                            {t('mediu_impact')}
                          </h5>
                          <p
                            className='u-custom-font u-font-montserrat u-text u-text-4'
                            data-animation-name='customAnimationIn'
                            data-animation-duration={1000}>
                            {t('nu_produc_poluanti')}
                          </p>
                          <h5
                            className='u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-default u-text-5'
                            data-animation-name='customAnimationIn'
                            data-animation-duration={1000}>
                            {t('noi_oportunitati')}
                          </h5>
                          <p
                            className='u-custom-font u-font-montserrat u-text u-text-6'
                            data-animation-name='customAnimationIn'
                            data-animation-duration={1000}>
                            {t('info_desc_1')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='u-size-30 u-size-60-md' style={{}}>
                    <div className='u-layout-row' style={{}}>
                      <div
                        className='u-align-left u-container-style u-hover-feature u-image u-layout-cell u-left-cell u-size-60 u-image-1'
                        src
                        data-image-width={1280}
                        data-image-height={655}
                        data-animation-name='customAnimationIn'
                        data-animation-duration={1000}
                        data-animation-direction>
                        <div
                          className='u-container-layout u-valign-middle u-container-layout-2'
                          src
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </S.Why>
      </section>
    </>
  );
};
export default Why;
