import * as S from './Asorty/styles';
import { useTranslation } from 'react-i18next';

const Castiguri = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <section className='u-clearfix u-section-5' id='sec-3c15'>
      <S.Castiguri>
        <img
          src='/images/LGiLE-T-TMD-059-107_LR5-72HPH_535-555M_Page_1__27223.png'
          className='side_img'
        />
        <div className='u-clearfix u-sheet u-valign-middle u-sheet-1'>
          <div className='u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1'>
            <div className='u-layout' style={{}}>
              <div className='u-layout-row' style={{}}>
                <div
                  className='u-align-center hide_mobile u-container-style u-layout-cell u-right-cell u-size-25 u-size-xs-60 u-layout-cell-1'
                  src>
                  <div
                    className='u-container-layout u-valign-middle u-container-layout-1'
                    src>
                    <img
                      className='u-image u-image-1 '
                      src='images/SolarPanelassemblyconstruction.jpg'
                      data-image-width={939}
                      data-image-height={567}
                      data-animation-name='customAnimationIn'
                      data-animation-duration={1000}
                    />
                  </div>
                </div>
                <div
                  className='u-container-style u-layout-cell u-left-cell u-size-35 u-size-xs-60 u-white u-layout-cell-2'
                  src
                  data-animation-name='customAnimationIn'
                  data-animation-duration={1000}>
                  <div className='u-container-layout u-container-layout-2'>
                    <div className='u-align-center u-clearfix u-group-elements u-group-elements-1'>
                      <span className='u-custom-color-2 u-icon u-icon-circle u-icon-1'>
                        <svg
                          className='u-svg-link'
                          preserveAspectRatio='xMidYMin slice'
                          viewBox='0 0 32 32'
                          style={{}}>
                          <use
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            xlinkHref='#svg-859a'
                          />
                        </svg>
                        <svg
                          className='u-svg-content'
                          viewBox='0 0 32 32'
                          id='svg-859a'>
                          <g>
                            <path d='M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z' />
                          </g>
                        </svg>
                      </span>
                      <h3 className='u-custom-font u-font-montserrat u-text u-text-1'>
                        <span className='u-file-icon u-icon u-icon-2'>
                          <img src='images/download.png' alt='' />
                        </span>
                        {t('a_castigat')}
                      </h3>
                      <img
                        className='u-image u-image-1 mobile_img'
                        src='images/SolarPanelassemblyconstruction.jpg'
                        data-image-width={939}
                        data-image-height={567}
                        data-animation-name='customAnimationIn'
                        data-animation-duration={1000}
                      />
                      <ol
                        className='u-align-left u-custom-font u-font-montserrat u-text u-text-2'
                        data-animation-name='customAnimationIn'
                        data-animation-duration={1000}
                        data-animation-direction>
                        <li>
                          <span className='u-text-custom-color-2'>
                            {t('test_ciclu')}
                          </span>{' '}
                          {t('test_1_rezultat')}
                        </li>
                        <li>
                          <span className='u-text-custom-color-2'>
                            {t('test2')}
                          </span>{' '}
                          {t('rezultate_12')}
                        </li>
                        <li>
                          <span className='u-text-custom-color-2'>
                            {t('test_efort')}
                          </span>{' '}
                          {t('test_efort_result')}
                        </li>
                        <li>
                          <span className='u-text-custom-color-2'>
                            {t('test_1231')}
                          </span>{' '}
                          {t('test_1231_result')}
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </S.Castiguri>
    </section>
  );
};

export default Castiguri;
