import * as S from './styles';
import { useTranslation } from 'react-i18next';

const Asorty = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <S.Asorty>
      <h1>{t('optiuni')}</h1>
      <div>
        <div>
          <img src='/images/Enel-X-sistem-PV-LTA-Mondial2.jpg' />
          <h3>{t('obiecte_industriale')}</h3>
        </div>
        <div>
          <img src='/images/solar-panels-1477987_1280.jpg' />
          <h3>{t('obiecte_rez')}</h3>
        </div>
        <div>
          <img
            className='last'
            src='/images/Longi-Monocrystalline-Solar-Panel-Wholesale-Price.png'
          />
          <h3>{t('vanzare_echip')}</h3>
        </div>
      </div>
      <a href='#contactați-ne'>{t('contact-us-link')}</a>
    </S.Asorty>
  );
};

export default Asorty;
