import styled from 'styled-components';

export const Gallery = styled.div`
  max-width: 1140px;
  margin: 64px auto;
  @media (max-width: 768px) {
    padding: 0 30px;
    max-width: 100%;
    width: 100%;
  }
  h1 {
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 1.1;
    text-align: center;
    font-family: Montserrat, sans-serif !important;
    margin-bottom: 72px;
    @media (max-width: 768px) {
      font-size: 28px;
    }
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 16px;
    & > div {
      width: 550px;
      height: 350px;
      position: relative;
      -webkit-transition: opacity 0.6s ease-in-out;
      -moz-transition: opacity 0.6s ease-in-out;
      -o-transition: opacity 0.6s ease-in-out;
      transition: opacity 0.6s ease-in-out;
      border-radius: 16px;
      overflow: hidden;
      @media (max-width: 768px) {
        width: 350px;
        height: 250px;
      }
      .logo {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.55);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3 ease-in-out;
        visibility: visible;
        opacity: 1;
        -webkit-transition: opacity 0.6s ease-in-out;
        -moz-transition: opacity 0.6s ease-in-out;
        -o-transition: opacity 0.6s ease-in-out;
        transition: opacity 0.6s ease-in-out;

        img {
          max-width: 200px;
          height: auto;
          @media (max-width: 768px) {
            max-width: 130px;
          }
        }
        .bee {
          max-width: 150px;
          @media (max-width: 768px) {
            max-width: 100px;
          }
        }
      }
      &:hover {
        transition: 0.6 ease-in-out;
        .logo {
          transition: 0.6 ease-in-out;
          visibility: hidden;
          opacity: 0;
        }
      }
      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
`;
