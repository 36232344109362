import { useTranslation } from 'react-i18next';

const Servicii = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <section className='u-align-center u-clearfix u-section-8' id='sec-7e34'>
      <div id='servicii' style={{ position: 'relative', top: '-100px' }}></div>
      <div className='u-clearfix u-sheet u-sheet-1'>
        <h1 className='u-custom-font u-font-montserrat u-text u-text-default u-text-1'>
          {t('services')}
        </h1>
        <div className='u-list u-list-1'>
          <div className='u-repeater u-repeater-1'>
            <div className='u-container-style u-list-item u-repeater-item'>
              <div className='u-container-layout u-similar-container u-valign-top u-container-layout-1'>
                <p
                  className='u-align-left u-custom-item u-text u-text-custom-color-5 u-text-2'
                  data-animation-name='customAnimationIn'
                  data-animation-duration={1000}
                  data-animation-direction>
                  {t('va_va_vizita')}
                </p>
                <ul
                  className='u-align-left u-custom-font u-custom-item u-custom-list u-font-montserrat u-text u-text-3'
                  data-animation-name='customAnimationIn'
                  data-animation-duration={1000}
                  data-animation-direction>
                  <li>
                    <div className='u-list-icon u-list-icon-1'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('personal_vizit')}
                  </li>
                  <li>
                    <div className='u-list-icon u-list-icon-2'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('examina_acoperisul')}
                  </li>
                  <li>
                    <div className='u-list-icon u-list-icon-3'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('analiza_ponetialul')}
                  </li>
                  <li>
                    <div className='u-list-icon u-list-icon-4'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('vom_dimensiona')}
                  </li>
                </ul>
              </div>
            </div>
            <div className='u-container-style u-list-item u-repeater-item'>
              <div className='u-container-layout u-similar-container u-valign-top u-container-layout-2'>
                <p
                  className='u-align-left u-custom-item u-text u-text-custom-color-5 u-text-4'
                  data-animation-name='customAnimationIn'
                  data-animation-duration={1000}
                  data-animation-direction>
                  {t('vom_instala_echipamentul')}
                </p>
                <ul
                  className='u-align-left u-custom-font u-custom-item u-custom-list u-font-montserrat u-text u-text-5'
                  data-animation-name='customAnimationIn'
                  data-animation-duration={1000}
                  data-animation-direction>
                  <li>
                    <div className='u-list-icon u-list-icon-5'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('in_cateva_zile')}
                  </li>
                  <li>
                    <div className='u-list-icon u-list-icon-5'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('vom_verifica_conecta')}
                  </li>
                  <li>
                    <div className='u-list-icon u-list-icon-5'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('actul_corespundere')}
                  </li>
                </ul>
              </div>
            </div>
            <div className='u-container-style u-list-item u-repeater-item'>
              <div className='u-container-layout u-similar-container u-valign-top u-container-layout-3'>
                <p
                  className='u-align-left u-custom-item u-text u-text-custom-color-5 u-text-6'
                  data-animation-name='customAnimationIn'
                  data-animation-duration={1000}
                  data-animation-direction>
                  {t('intocmi_documentele')}
                </p>
                <ul
                  className='u-align-left u-custom-font u-custom-item u-custom-list u-font-montserrat u-text u-text-7'
                  data-animation-name='customAnimationIn'
                  data-animation-duration={1000}
                  data-animation-direction>
                  <li>
                    <div className='u-list-icon u-list-icon-8'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('consulta_obtinerea')}
                  </li>
                  <li>
                    <div className='u-list-icon u-list-icon-9'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('vom_elabora')}
                  </li>
                  <li>
                    <div className='u-list-icon u-list-icon-10'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('vom_pregati_actele')}
                  </li>
                  <li>
                    <div className='u-list-icon u-list-icon-11'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('geef_consultare')}
                  </li>
                </ul>
              </div>
            </div>
            <div className='u-container-style u-list-item u-repeater-item'>
              <div className='u-container-layout u-similar-container u-valign-top u-container-layout-4'>
                <p
                  className='u-align-left u-custom-item u-text u-text-custom-color-5 u-text-8'
                  data-animation-name='customAnimationIn'
                  data-animation-duration={1000}
                  data-animation-direction>
                  {t('incepi_ecnomie')}
                </p>
                <ul
                  className='u-align-left u-custom-font u-custom-item u-custom-list u-font-montserrat u-text u-text-9'
                  data-animation-name='customAnimationIn'
                  data-animation-duration={1000}
                  data-animation-direction>
                  <li>
                    <div className='u-list-icon u-list-icon-12'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('asista_incheierea')}
                  </li>
                  <li>
                    <div className='u-list-icon u-list-icon-12'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512 512'
                        id='svg-e5e7'>
                        <ellipse
                          cx={256}
                          cy={256}
                          fill='currentColor'
                          rx={256}
                          ry='255.8'
                        />
                        <path
                          d='m235.5 392.1-121.1-94.3 34.4-44.2 74.4 57.9 122.6-177 46.1 31.9z'
                          fill='#fff'
                        />
                      </svg>
                    </div>
                    {t('invata_cum')}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='u-repeater u-repeater-2'>
          <div className='u-container-style u-list-item u-repeater-item'>
            <div className='u-container-layout u-similar-container u-valign-bottom u-container-layout-5' />
          </div>
          <div className='u-container-style u-list-item u-repeater-item'>
            <div className='u-container-layout u-similar-container u-container-layout-6' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Servicii;
