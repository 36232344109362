import * as S from './Asorty/styles';
import { useState } from 'react';
import i18next from '../i18n';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from './useWindowDimensions';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { t, i18n } = useTranslation(['translation']);
  const { height, width } = useWindowDimensions();
  return (
    <S.Navbar>
      <header className='u-clearfix u-header u-header' id='sec-d35a'>
        <div className='u-clearfix u-sheet u-sheet-1 wrapper_navbar'>
          <a
            href='#'
            className={`u-image u-logo u-image-1 no_margin ${
              width >= 768 ? '' : 'min_logo'
            }`}>
            <img
              src={`images/logos/${
                width >= 768 ? 'GRANDSOLAR.png' : 'company.png'
              }`}
              className='u-logo-image u-logo-image-1'
              onClick={() => setShowMenu(false)}
            />
          </a>
          <nav className='u-menu u-menu-one-level u-offcanvas u-menu-1'>
            <div
              className='menu-collapse u-custom-font u-font-montserrat'
              style={{
                fontSize: '1rem',
                letterSpacing: '0px',
                textTransform: 'uppercase',
                fontWeight: 400,
              }}>
              <button
                className='u-button-style u-custom-active-border-color u-custom-active-color u-custom-border u-custom-border-color u-custom-borders u-custom-hover-border-color u-custom-hover-color u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-text-active-color u-custom-text-color u-custom-text-hover-color u-custom-top-bottom-menu-spacing u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base burger'
                href='#'>
                <svg
                  className='u-svg-link'
                  viewBox='0 0 24 24'
                  onClick={() => setShowMenu(!showMenu)}>
                  <use
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    xlinkHref='#menu-hamburger'
                  />
                </svg>
                <svg
                  className='u-svg-content'
                  version='1.1'
                  id='menu-hamburger'
                  viewBox='0 0 16 16'
                  x='0px'
                  y='0px'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  xmlns='http://www.w3.org/2000/svg'>
                  <g>
                    <rect y={1} width={16} height={2} />
                    <rect y={7} width={16} height={2} />
                    <rect y={13} width={16} height={2} />
                  </g>
                </svg>
              </button>
            </div>
            <div className='u-custom-menu u-nav-container'>
              <ul className='u-custom-font u-font-montserrat u-nav u-spacing-1 u-unstyled u-nav-1 list_hide'>
                <li className='u-nav-item'>
                  <a
                    className='u-text-grey-90'
                    href='#produse'
                    style={{ padding: '10px 14px' }}>
                    {t('produse')}
                  </a>
                </li>
                <li className='u-nav-item'>
                  <a
                    className='u-text-grey-90'
                    style={{ padding: '10px 14px' }}
                    href='#servicii'>
                    {t('Servicii')}
                  </a>
                </li>
                <li className='u-nav-item'>
                  <a
                    className='u-text-grey-90'
                    style={{ padding: '10px 14px' }}
                    href='#despre-noi'>
                    {t('despre')}
                  </a>
                </li>
                <li className='u-nav-item'>
                  <a
                    className='u-text-grey-90'
                    style={{ padding: '10px 14px' }}
                    href='#de-ce-noi'>
                    {t('dece')}
                  </a>
                </li>
                <li className='u-nav-item'>
                  <a
                    className=' u-nav-link u-text-grey-90 u-text-hover-white'
                    style={{ padding: '10px 19px 10px 14px' }}
                    href='#contactați-ne'>
                    {t('contact-us-link')}
                  </a>
                </li>
              </ul>
            </div>
            {showMenu && (
              <S.MobileMenu>
                <div>
                  <ul>
                    {' '}
                    <li className='u-nav-item'>
                      <a
                        className='u-text-grey-90'
                        href='#produse'
                        style={{ padding: '10px 14px' }}
                        onClick={() => setShowMenu(false)}>
                        {t('produse')}
                      </a>
                    </li>
                    <li className='u-nav-item'>
                      <a
                        className='u-text-grey-90'
                        style={{ padding: '10px 14px' }}
                        href='#servicii'
                        onClick={() => setShowMenu(false)}>
                        {t('Servicii')}
                      </a>
                    </li>
                    <li className='u-nav-item'>
                      <a
                        className='u-text-grey-90'
                        style={{ padding: '10px 14px' }}
                        href='#despre-noi'
                        onClick={() => setShowMenu(false)}>
                        {t('despre')}
                      </a>
                    </li>
                    <li className='u-nav-item'>
                      <a
                        className='u-text-grey-90'
                        style={{ padding: '10px 14px' }}
                        href='#de-ce-noi'
                        onClick={() => setShowMenu(false)}>
                        {t('dece')}
                      </a>
                    </li>
                    <li className='u-nav-item'>
                      <a
                        className=' u-nav-link u-text-grey-90 u-text-hover-white'
                        style={{ padding: '10px 19px 10px 14px' }}
                        href='#contactați-ne'
                        onClick={() => setShowMenu(false)}>
                        {t('contact-us-link')}
                      </a>
                    </li>
                  </ul>
                  <ul className='language_switcher'>
                    <li onClick={() => i18next.changeLanguage('ro')}>RO</li>
                    <li onClick={() => i18next.changeLanguage('ru')}>RU</li>
                    <li onClick={() => i18next.changeLanguage('en-GB')}>EN</li>
                  </ul>
                </div>
              </S.MobileMenu>
            )}
          </nav>
          <div className='u-list u-list-1 language-switcher'>
            <div className='u-repeater u-repeater-1'>
              <div className='u-container-style u-list-item u-repeater-item'>
                <div className='u-container-layout u-similar-container u-container-layout-1'>
                  <p
                    onClick={() => i18next.changeLanguage('ro')}
                    className={`u-custom-item u-heading-font u-text  u-text-default u-text-1 lang-p ${
                      i18next.language === 'ro' ? 'u-text-custom-color-1' : ''
                    }`}>
                    RO
                  </p>
                </div>
              </div>
              <div className='u-container-style u-list-item u-repeater-item'>
                <div className='u-container-layout u-similar-container u-container-layout-2'>
                  <p
                    onClick={() => {
                      console.log('asdas');
                      i18next.changeLanguage('ru', (err, i) => {});
                    }}
                    className={`u-custom-item u-heading-font u-text  u-text-default u-text-1 lang-p ${
                      i18next.language === 'ru' ? 'u-text-custom-color-1' : ''
                    }`}>
                    RU
                  </p>
                </div>
              </div>
              <div className='u-container-style u-list-item u-repeater-item'>
                <div className='u-container-layout u-similar-container u-container-layout-3'>
                  <p
                    onClick={() => i18next.changeLanguage('en-GB')}
                    className={`u-custom-item u-heading-font u-text  u-text-default u-text-1 lang-p ${
                      i18next.language.toLowerCase() === 'en-gb'
                        ? 'u-text-custom-color-1'
                        : ''
                    }`}>
                    EN
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </S.Navbar>
  );
};

export default Navbar;
