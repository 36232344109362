import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import * as S from './Asorty/styles';
import { useTranslation } from 'react-i18next';

const Invertor = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <section
      className='skrollable u-clearfix u-expand-resize u-image u-parallax u-shading u-section-6'
      id='sec-4b14'
      data-image-width={2000}
      data-image-height={1333}>
      <S.Invertor>
        <div className='u-clearfix u-sheet u-valign-middle u-sheet-1'>
          <div className='u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1'>
            <Swiper
              spaceBetween={120}
              slidesPerView='auto'
              navigation={true}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Autoplay]}
              loop={true}
              breakpoints={{
                // when window width is >= 640px
                100: {
                  slidesPerView: 1,
                },
              }}
              className='invertor-swiper'>
              <SwiperSlide>
                <S.SwiperInvertor>
                  <div className='u-layout'>
                    <div className='u-layout-row'>
                      <div
                        className='u-container-style u-grey-5 u-layout-cell u-left-cell u-size-30 u-layout-cell-1'
                        src
                        data-animation-name
                        data-animation-duration={0}
                        data-animation-direction>
                        <div className='content'>
                          <h2>
                            {' '}
                            {t('invertor')}
                            <img src='/images/logos/6.png' />
                          </h2>
                          <h6>
                            <img src='/images/progress.png' />
                            {t('eficienta')}
                          </h6>
                          <p>
                            <img src='/images/play-button.png' />
                            {t('max_eficienta')}
                          </p>
                          <p>
                            <img src='/images/play-button.png' />
                            {t('curent_de_sir')}
                          </p>
                          <p>
                            <img src='/images/play-button.png' />
                            {t('gama_larga')}
                          </p>
                          <h6>
                            <img src='/images/lightbulb.png' />
                            Smart
                          </h6>
                          <p>
                            <img src='/images/play-button.png' />
                            {t('controlul_puterii')}
                          </p>
                          <p>
                            <img src='/images/play-button.png' />
                            {t('suporta_roboti')}
                          </p>
                          <p>
                            <img src='/images/play-button.png' />
                            {t('scanati_pentru')}
                          </p>

                          <h6>
                            <img src='/images/handshake.png' />
                            {t('economie_header')}
                          </h6>
                          <p>
                            <img src='/images/play-button.png' />
                            {t('design_compact')}
                          </p>
                          <p>
                            <img src='/images/play-button.png' />
                            {t('raport_ac')}
                          </p>
                          <p>
                            <img src='/images/play-button.png' />
                            {t('suporta_module')}
                          </p>
                        </div>
                      </div>
                      <div className='u-align-left wrapper-image-invertor u-container-style u-grey-5 u-layout-cell u-right-cell u-size-30 u-layout-cell-2 image_cell invertor_first'>
                        <img
                          src='/images/21-1.png'
                          className='image_invertor'
                        />
                      </div>
                    </div>
                  </div>
                </S.SwiperInvertor>
              </SwiperSlide>
              <SwiperSlide>
                <div className='u-layout border-radius'>
                  <div className='u-layout-row'>
                    <div
                      className='u-container-style u-grey-5 hide-mobile u-layout-cell u-left-cell u-size-30 u-layout-cell-1'
                      src
                      data-animation-name
                      data-animation-duration={0}
                      data-animation-direction>
                      <div className='u-container-layout u-container-layout-1'>
                        <img
                          className='u-image u-image-1'
                          src='images/sma-inverter-tripower-stp-15000tl.jpg'
                          data-image-width={500}
                          data-image-height={650}
                        />
                        <div className='u-opacity u-opacity-5 u-shape u-shape-svg u-text-custom-color-3 u-shape-1'>
                          <svg
                            className='u-svg-link'
                            preserveAspectRatio='none'
                            viewBox='0 0 160 160'
                            style={{}}>
                            <use
                              xmlnsXlink='http://www.w3.org/1999/xlink'
                              xlinkHref='#svg-d99c'
                            />
                          </svg>
                          <svg
                            className='u-svg-content'
                            viewBox='0 0 160 160'
                            x='0px'
                            y='0px'
                            id='svg-d99c'>
                            <path
                              d='M14.5,7.3c0,4-3.3,7.3-7.3,7.3S0,11.2,0,7.3S3.3,0,7.3,0S14.5,3.3,14.5,7.3z M7.3,24.2c-4,0-7.3,3.3-7.3,7.3
	s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3S11.2,24.2,7.3,24.2z M31.5,0c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3S35.5,0,31.5,0
	z M55.8,0c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3S63,11.2,63,7.3S59.7,0,55.8,0z M80,0c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3
	s7.3-3.3,7.3-7.3S84,0,80,0z M104.2,0c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3c4,0,7.3-3.3,7.3-7.3S108.2,0,104.2,0z M128.5,0
	c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3c4,0,7.3-3.3,7.3-7.3S132.5,0,128.5,0z M152.7,0c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3
	c4,0,7.3-3.3,7.3-7.3S156.7,0,152.7,0z M31.5,24.2c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3S35.5,24.2,31.5,24.2z
	 M55.8,24.2c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3S59.7,24.2,55.8,24.2z M80,24.2c-4,0-7.3,3.3-7.3,7.3
	s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3S84,24.2,80,24.2z M104.2,24.2c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3c4,0,7.3-3.3,7.3-7.3
	S108.2,24.2,104.2,24.2z M128.5,24.2c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3c4,0,7.3-3.3,7.3-7.3S132.5,24.2,128.5,24.2z M7.3,48.5
	c-4,0-7.3,3.3-7.3,7.3S3.3,63,7.3,63s7.3-3.3,7.3-7.3S11.2,48.5,7.3,48.5z M31.5,48.5c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3
	s7.3-3.3,7.3-7.3S35.5,48.5,31.5,48.5z M55.8,48.5c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3S59.7,48.5,55.8,48.5z
	 M80,48.5c-4,0-7.3,3.3-7.3,7.3S76,63,80,63s7.3-3.3,7.3-7.3S84,48.5,80,48.5z M104.2,48.5c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3
	c4,0,7.3-3.3,7.3-7.3S108.2,48.5,104.2,48.5z M7.3,72.7C3.3,72.7,0,76,0,80s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3S11.2,72.7,7.3,72.7z
	 M31.5,72.7c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3S35.5,72.7,31.5,72.7z M55.8,72.7c-4,0-7.3,3.3-7.3,7.3
	s3.3,7.3,7.3,7.3S63,84,63,80S59.7,72.7,55.8,72.7z M80,72.7c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3S84,72.7,80,72.7
	z M7.3,97c-4,0-7.3,3.3-7.3,7.3c0,4,3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3C14.5,100.3,11.2,97,7.3,97z M31.5,97c-4,0-7.3,3.3-7.3,7.3
	c0,4,3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3C38.8,100.3,35.5,97,31.5,97z M55.8,97c-4,0-7.3,3.3-7.3,7.3c0,4,3.3,7.3,7.3,7.3
	s7.3-3.3,7.3-7.3C63,100.3,59.7,97,55.8,97z M7.3,121.2c-4,0-7.3,3.3-7.3,7.3c0,4,3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3
	C14.5,124.5,11.2,121.2,7.3,121.2z M31.5,121.2c-4,0-7.3,3.3-7.3,7.3c0,4,3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3
	C38.8,124.5,35.5,121.2,31.5,121.2z M7.3,145.5c-4,0-7.3,3.3-7.3,7.3c0,4,3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3
	C14.5,148.8,11.2,145.5,7.3,145.5z'
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className='u-align-left u-container-style u-grey-5 u-layout-cell u-right-cell u-size-30 u-layout-cell-2'>
                      <div className='u-container-layout u-container-layout-2'>
                        <h1
                          className='u-align-left sma_header u-custom-font u-font-montserrat u-text u-title u-text-1'
                          data-animation-name='customAnimationIn'
                          data-animation-duration={1000}>
                          <span style={{ fontWeight: 400 }}>
                            {t('invertor')}
                          </span>
                          <img
                            className='image_sma'
                            src='images/Logo_SMA.svg.png'
                            alt=''
                            data-image-width={1200}
                            data-image-height={789}
                          />
                        </h1>

                        <h3
                          className='u-align-left u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-2'
                          data-animation-name='customAnimationIn'
                          data-animation-duration={1000}>
                          {t('de_ce_sma')}
                        </h3>
                        <p
                          className='u-align-left u-large-text u-text u-text-variant u-text-3'
                          data-animation-name='customAnimationIn'
                          data-animation-duration={1000}>
                          {t('fabricat_unde')}
                          <br />
                          <br />
                          {t('sma_a_castigat')}
                        </p>
                        <h3
                          className='u-align-left u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-4'
                          data-animation-name='customAnimationIn'
                          data-animation-duration={1000}>
                          {t('inovare')}
                        </h3>
                        <ul
                          className='u-align-left u-custom-font u-font-montserrat u-text u-text-5'
                          data-animation-name='customAnimationIn'
                          data-animation-duration={1000}>
                          <li>{t('eth_lan')}</li>
                          <li>
                            {t('compatibil_cu_solutii')}
                            &nbsp;
                          </li>
                          <li>{t('serviciu_integrat')}</li>
                          <li>{t('sma_integrat')}</li>
                          <li>{t('lansator_lca')}</li>
                        </ul>
                        <img
                          className='u-image u-image-1 img_responsive'
                          src='images/sma-inverter-tripower-stp-15000tl.jpg'
                          data-image-width={500}
                          data-image-height={650}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </S.Invertor>
    </section>
  );
};
export default Invertor;
