import styled, { css } from 'styled-components';

export const Despre = styled.div`
  @media (max-width: 768px) {
    .u-layout-row {
      flex-direction: column-reverse;
    }
  }
`;

export const menuSlidesAnimation = css`
  @keyframes mobile-menu-slides {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  -webkit-animation: mobile-menu-slides 0.5s 1; /* Safari 4+ */
  -moz-animation: mobile-menu-slides 0.5s 1; /* Fx 5+ */
  -o-animation: mobile-menu-slides 0.5s 1; /* Opera 12+ */
  animation: mobile-menu-slides 0.5s 1; /* IE 10+, Fx 29+ */
`;

export const MobileMenu = styled.div`
  z-index: 9;
  position: fixed;
  top: 69px;
  width: 100%;
  left: 0;
  height: 320px;
  background: white;
  display: flex;
  align-items: flex-start;
  ${menuSlidesAnimation}
  ul:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 0 0 12px;
    padding-top: 36px;
    a {
      color: #212121 !important;
      padding: 0 !important;
      font-size: 18px;
    }
  }
  .language_switcher {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 36px 0 0 12px;
    justify-content: flex-start;
    gap: 16px;
    li {
      color: #212121 !important;
      padding: 0 !important;
      font-size: 18px;
    }
  }
`;

export const Invertor = styled.div`
  .img_responsive {
    display: none;
  }
  @media (max-width: 768px) {
    .swiper-slide {
      height: auto;
    }
    .hide-mobile {
      display: none !important;
    }
    .img_responsive {
      display: block;
    }
    .u-layout-cell-2 {
      min-height: -webkit-fill-available;
    }
    .u-container-layout-2 {
      padding: 0 30px;
    }
    .img_responsive {
      margin: 40px auto 0 auto !important;
    }
    .u-layout-wrap-1 {
      margin: 0 !important;
    }
    .u-layout {
      min-height: 100%;
      background: #f2f2f2;
    }
    h1,
    h2 {
      font-size: 32px !important;
      img {
        max-width: 130px !important;
      }
    }
    .u-image-2 {
      margin: -68px 136px 0 190px !important;
    }
    .u-sheet-1 {
      margin: 0 !important;
      width: 100% !important;
    }
    .border-radius {
      border-radius: 0 !important;
    }
    .wrapper-image-invertor {
      padding-right: 0 !important;
    }
    .image_invertor {
      width: 320px !important;
      margin: 0 auto;
      height: 300px !important;
    }
  }
`;

export const Castiguri = styled.div`
  .mobile_img {
    display: none;
  }
  @media (max-width: 768px) {
    .side_img {
      display: none;
    }
    .hide_mobile {
      display: none;
    }
    .u-sheet-1 {
      width: 100% !important;
      margin: 0 !important;
    }
    .u-container-layout-2 {
      padding: 0 30px !important;
    }
    .u-group-elements-1 {
      margin: 0 !important;
      width: 100% !important;
      margin-bottom: 64px !important;
    }
    .u-icon-circle {
      margin: 0 auto;
    }
    .mobile_img {
      margin: 64px auto;
    }
  }
`;

export const Panel = styled.div`
  h1 {
    font-weight: 400 !important;
  }
  .logo {
    display: none;
  }
  .more_details {
    color: rgb(97, 141, 185) !important;
    display: flex;
    align-items: center;
    &:active,
    &:focus {
      outline: none;
      border: none;
    }
  }
  @media (max-width: 768px) {
    .u-sheet {
      width: 100% !important;
      margin: 0 !important;
    }
    .hide_mobile_link {
      display: none !important;
    }
    .no-margin {
      margin: 0 !important;
    }
    .u-group-1 {
      box-shadow: none !important;
    }
    .u-image-2 {
      margin: 36px auto !important;
      width: 290px;
      height: 350px;
    }
    .wrapper_panel {
      display: flex;
      flex-direction: column-reverse;
    }
    h1 {
      text-align: center !important;
      padding-bottom: 16px;
    }
    .u-border-2 {
      width: 100% !important;
    }
    .logo {
      display: block;
      margin-top: 36px;
    }
    .non-responsive {
      display: none;
    }
    .u-group-elements-1 {
      margin: 0 !important;
      width: 100% !important;
      box-sizing: border-box;
    }
    .u-group-elements-2 {
      width: 100%;
      margin-bottom: 64px;
    }
  }
`;

export const SwiperInvertor = styled.div`
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 768px) {
    border-radius: 0;
    height: -webkit-fill-available;
    min-height: 100%;
  }
  .image_invertor {
    max-width: 350px;
  }
  .image_cell {
    align-items: center;
    justify-content: flex-end;
    padding-right: 60px;
  }
  .content {
    padding: 45px 83px 30px 37px;
    h2 {
      display: flex;
      align-items: center;
      font-size: 3rem;
      font-family: Montserrat, sans-serif !important;
      font-weight: 400;
      line-height: 1.1;
      img {
        max-width: 190px;
      }
    }
    h6,
    h5 {
      color: #39ae53 !important;
      font-family: Montserrat, sans-serif !important;
      font-weight: 400;
      font-size: 1.875rem;
      display: flex;
      align-items: center;
      img {
        max-width: 30px;
        margin-right: 10px;
      }
    }
    p {
      display: flex;
      align-items: flex-start;
      margin: 0;
      img {
        max-width: 10px;
        margin-top: 7px;
        margin-right: 8px;
      }
    }
  }
`;

export const Hero = styled.div`
  @media (max-width: 768px) {
    .u-sheet-1 {
      width: 100% !important;
      padding: 0 40px;
      .u-group-elements-1 {
        width: 100%;
        h1 {
          font-size: 48px !important;
        }
        p {
          font-size: 18px;
          font-family: Montserrat, sans-serif !important;
          margin: 16px 0 0;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .u-sheet-1 .u-group-elements-1 h1 {
      font-size: 42px !important;
    }
  }
  @media (max-width: 400px) {
    .u-sheet-1 .u-group-elements-1 h1 {
      font-size: 32px !important;
    }
    .u-sheet-1 .u-group-elements-1 p {
      font-size: 16px;
    }
  }
`;

export const Why = styled.div`
  @media (max-width: 768px) {
    .u-sheet-1 {
      margin-top: 64px !important;
      width: 100% !important;
      padding: 0 30px !important;
      box-sizing: border-box;
      h2,
      p,
      h5 {
        margin-left: 0 !important;
      }
    }
    .text-no-margin {
      margin-top: 0 !important;
    }
    .new-row {
      flex-direction: column-reverse;
    }
    .u-gutter-10 {
      margin-top: 0 !important;
    }
    .new_shit {
      margin-top: 0 !important;
    }
  }
`;

export const Navbar = styled.div`
  .u-menu {
    opacity: 1 !important;
  }
  .lang-p {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    .language-switcher {
      display: none;
    }
    .list_hide {
      display: none !important;
    }
    .burger {
      z-index: 9999;
    }
    .u-logo-image-1 {
      z-index: 9999;
    }
    .wrapper_navbar {
      width: 100% !important;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      z-index: 9999;
      &:before,
      &:after {
        display: block;
        content: unset;
      }
      .u-image-1 {
        margin: 0;
      }
      .u-menu {
        display: block;
        margin: 0;
      }
    }
  }
`;

export const ResponsiveSlide = styled.div`
  .k2_montare {
    display: none;
  }
  @media (max-width: 768px) {
    .u-image-2 {
      background: none !important;
      display: none !important;
    }
    .u-group-elements-1 {
      width: 100% !important;
    }
    h2 {
      margin-right: 0 !important;
      text-align: left !important;
    }
    .k2_montare {
      display: block;
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: center;
      margin: 32px 0;
    }
  }
`;

export const NewSlide = styled.div`
  max-width: 1140px;
  margin: 0 auto 0 auto;
  position: relative;
  display: flex;
  padding-top: 80px;
  padding-bottom: 64px;
  .mobile_version {
    display: none;
  }
  @media (max-width: 768px) {
    padding: 0;
    flex-direction: column-reverse;
    background: rgba(255, 255, 255, 0.3);
    .left-side {
      display: none !important;
    }
    .content {
      width: 100% !important;
    }
    h1 {
      font-size: 32px !important;
    }

    .mobile_version {
      display: block;
      width: 100%;
      height: 250px;
      object-fit: cover;
      object-position: center;
      margin-top: 26px;
      margin-bottom: 26px;
      border-radius: 8px;
    }
  }
  .content {
    width: 60%;
    background: rgba(254, 254, 254, 0.8);
    padding: 35px 45px;
    @media (max-width: 768px) {
      padding: 35px 30px;
    }
    h1 {
      font-family: Montserrat, sans-serif !important;
      margin: 25px 20px 24px 0;
      font-weight: 400;
      font-size: 2.05rem;
      line-height: 1.1;
      img {
        max-width: 120px;
        margin-bottom: -10px;
        margin-left: 8px;
      }
    }
    h3 {
      font-size: 24px;
    }
  }
`;

export const InfoCard = styled.div`
  max-width: 100%;
  padding: 16px 32px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  margin: 15px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  @media (max-width: 768px) {
    width: 100%;
  }
  cursor: pointer;
  &:hover {
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    transform: scale(1.05);
    img {
      transform: scale(1.05);
    }
  }
  & > div {
    p:first-child {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 0;
    }
    p:last-child {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
    }
  }

  img {
    max-width: 30px;
    transition: 0.3s ease-in-out;
  }
`;
export const DeCeNoi = styled.div`
  @media (max-width: 768px) {
    .u-text-4 {
      margin: 0 !important;
    }
    .u-text-2 {
      font-size: 18px !important;
    }
    .my_list {
      margin: 16px 0 !important;
    }
    .free_consultation {
      font-size: 22px !important;
      text-align: center !important;
      margin: 0 auto !important;
    }
  }
`;

export const Asorty = styled.div`
  max-width: 1140px;
  margin: 64px auto 128px;
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    padding: 0 30px;
    margin: 0 auto 128px;
  }
  h1 {
    text-align: center;
    margin-bottom: 60px;
    font-family: Montserrat, sans-serif !important;
    font-size: 3rem;
  }
  a {
    color: #ffffff !important;
    background-color: #339d4b !important;
    padding: 7px 28px 9px 27px;
    font-size: 1.125rem;
    font-family: Montserrat, sans-serif !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: max-content;
    margin: 64px auto;
    text-align: center;
    display: flex;
    transition: 0.3s ease-in-out;
    &:hover {
      background: #1e5e2c !important;
    }
  }
  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    & > div {
      max-width: 32.5%;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: 0.3s ease-in-out;
      @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
      }
      &:hover {
        transform: scale(1.05);
      }
      img {
        max-width: 100%;
        max-height: 300px;
        height: 300px;
        object-fit: cover;
        object-position: center;
      }
      h3 {
        font-family: Montserrat, sans-serif !important;
        text-align: center;
        font-size: 24px;
      }
      .last {
        border-bottom: 1px solid #c2c2c2;
      }
    }
  }
`;
