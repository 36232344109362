import * as S from './styles';
import ImageViewer from 'react-simple-image-viewer';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const Gallery = () => {
  const { t, i18n } = useTranslation(['translation']);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    '/images/IMG_5163.jpg',
    '/images/IMG_5164.jpg',
    '/images/IMG_5165.jpg',
    '/images/IMG_5167.jpg',
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <S.Gallery>
        <h1>{t('cativa_din_clienti')}</h1>
        <div>
          <div onClick={() => openImageViewer(0)}>
            <img src='/images/IMG_5163.jpg' />
            <div className='logo'>
              <img src='/images/logos/agrodenidan.png' />
            </div>
          </div>
          <div onClick={() => openImageViewer(1)}>
            <img src='/images/IMG_5164.jpg' />
            <div className='logo'>
              <img src='/images/logos/Purcari.png' />
            </div>
          </div>
          <div onClick={() => openImageViewer(2)}>
            <img src='/images/IMG_5165.jpg' />
            <div className='logo'>
              <img src='/images/logos/soldi.png' />
            </div>
          </div>
          <div onClick={() => openImageViewer(3)}>
            <img src='/images/IMG_5167.jpg' />
            <div className='logo'>
              <img
                src='/images/logos/265139808_102815218921765_1235815164010312683_n.png'
                className='bee'
              />
            </div>
          </div>
        </div>
      </S.Gallery>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

export default Gallery;
