import * as S from './Asorty/styles';
import { useTranslation } from 'react-i18next';

const PanelPrincipal = ({ setShowModal }) => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <section
      className='skrollable u-align-left u-clearfix u-image u-parallax u-shading u-section-4'
      src
      data-image-width={2000}
      data-image-height={1335}
      id='sec-b574'>
      <div style={{ position: 'absolute', top: '-100px' }} id='produse'></div>
      <S.Panel>
        <div className='u-clearfix u-sheet u-sheet-1'>
          <div
            className='u-align-left u-container-style no-margin u-expanded-width u-group u-white u-group-1'
            data-animation-name='customAnimationIn'
            data-animation-duration={1000}>
            <div className='u-container-layout u-container-layout-1'>
              <h1 className='u-custom-font u-font-montserrat u-text u-text-default u-text-1'>
                {t('cea_mai_buna_solutie')}
              </h1>
              <div className='u-border-2 u-border-custom-color-2 u-line u-line-horizontal u-line-1' />
              <div className='wrapper_panel'>
                <div className='u-clearfix u-group-elements u-group-elements-1'>
                  <div className='u-clearfix u-group-elements u-group-elements-2'>
                    <img
                      className='u-image non-responsive u-image-contain u-image-default u-preserve-proportions u-image-1'
                      src='images/download.png'
                      alt=''
                      data-image-width={225}
                      data-image-height={53}
                    />
                    <h5 className='u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-default u-text-2'>
                      {t('eficienta_panourilor')}
                    </h5>
                    <ul className='u-custom-font u-font-montserrat u-text u-text-3'>
                      <li>{t('why_1')}</li>
                      <li>{t('why_2')}</li>
                      <li>{t('why_3')}</li>
                      <li>{t('why_4')}</li>
                      <li>{t('why_5')}</li>
                    </ul>
                    <button
                      href='#sec-9494'
                      onClick={() => setShowModal(true)}
                      className='more_details hide_mobile_link u-border-active-palette-2-base u-border-hover-palette-1-base u-border-none u-btn u-button-style u-dialog-link u-none u-text-custom-color-5 u-btn-1'>
                      &nbsp; {t('multe_detalii')}
                      &nbsp;
                      <span className='u-file-icon u-icon u-text-custom-color-3'>
                        <img src='images/2989988-d39f21a4.png' alt='' />
                      </span>
                      &nbsp;&nbsp;
                    </button>
                  </div>
                </div>
                <div>
                  <img
                    className=' logo u-image u-image-contain u-image-default u-preserve-proportions u-image-1'
                    src='images/download.png'
                    alt=''
                    data-image-width={225}
                    data-image-height={53}
                  />
                  <img
                    className='u-image u-image-contain u-image-default u-image-2'
                    src='images/N_412_612_03cd344e74.png'
                    alt=''
                    data-image-width={430}
                    data-image-height={698}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </S.Panel>
    </section>
  );
};
export default PanelPrincipal;
