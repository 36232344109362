import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import { useTranslation } from 'react-i18next';

import * as S from './Asorty/styles';
const Montare = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <section className='u-clearfix u-section-7 swiper_section' id='sec-b355'>
      <Swiper
        spaceBetween={20}
        slidesPerView='auto'
        navigation={true}
        modules={[Navigation]}
        loop={true}
        breakpoints={{
          // when window width is >= 640px
          100: {
            slidesPerView: 1,
          },
        }}>
        <SwiperSlide>
          <div className='full_width_slider'>
            <S.NewSlide>
              <div className='content'>
                <h1>
                  {t('sisteme_de_montare')}{' '}
                  <img src='/images/logo-dome-solar-blanc.png' />
                </h1>
                <img src='/images/12.png' className='mobile_version' />

                <h3 class='u-align-left u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-default u-text-2'>
                  {t('dome_solar_why')}
                </h3>
                <ul className='u-align-left u-custom-font u-custom-list u-font-montserrat u-text u-text-default u-text-3'>
                  <li>
                    <div className='u-list-icon u-list-icon-1'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512.008 512.008'
                        id='svg-9e30'>
                        <path
                          d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                  <li>{t('ce_permite')}</li>
                  <li>
                    <div className='u-list-icon u-list-icon-1'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512.008 512.008'
                        id='svg-9e30'>
                        <path
                          d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                  <li>{t('o_solutie')}</li>
                  <li>
                    <div className='u-list-icon u-list-icon-1'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512.008 512.008'
                        id='svg-9e30'>
                        <path
                          d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                  <li>{t('usurinta')}</li>
                  <li>
                    <div className='u-list-icon u-list-icon-1'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512.008 512.008'
                        id='svg-9e30'>
                        <path
                          d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                  <li>{t('unde_se_utilizeaza')}</li>
                  <li>
                    <div className='u-list-icon u-list-icon-1'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512.008 512.008'
                        id='svg-9e30'>
                        <path
                          d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                  <li>{t('punte_standart')}</li>
                  <li>
                    <div className='u-list-icon u-list-icon-1'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512.008 512.008'
                        id='svg-9e30'>
                        <path
                          d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                  <li>{t('materiale_aluminiu')}</li>
                  <li>
                    <div className='u-list-icon u-list-icon-1'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512.008 512.008'
                        id='svg-9e30'>
                        <path
                          d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                  <li>{t('greutatea_lui')}</li>
                  <li>
                    <div className='u-list-icon u-list-icon-1'>
                      <svg
                        className='u-svg-content'
                        viewBox='0 0 512.008 512.008'
                        id='svg-9e30'>
                        <path
                          d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                  <li>{t('componenetele_lui')}</li>
                </ul>
              </div>
              <img src='/images/12.png' className='left-side' />
            </S.NewSlide>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='u-clearfix u-gutter-0 u-layout-wrap u-layout-wrap-1 responsive_slide_montare'>
            <S.ResponsiveSlide>
              <div className='u-layout' style={{}}>
                <div className='u-layout-row' style={{}}>
                  <div
                    className='u-container-style u-layout-cell u-left-cell u-size-26 u-size-xs-60 u-white u-layout-cell-1'
                    src
                    data-animation-name
                    data-animation-duration={0}
                    data-animation-direction>
                    <div className='u-container-layout u-container-layout-1'>
                      <div className='u-clearfix u-group-elements u-group-elements-1'>
                        <h2 className='u-align-center u-custom-font u-font-montserrat u-text u-text-1 text-align-flex'>
                          {t('sisteme_de_montare')}
                          <img
                            className=''
                            src='images/logo_512x512.png'
                            alt=''
                            data-image-width={512}
                            data-image-height={512}
                          />
                        </h2>

                        <h3 className='u-align-left u-custom-font u-font-montserrat u-text u-text-custom-color-2 u-text-default u-text-2'>
                          {t('de_ce_k2')}
                        </h3>
                        <img
                          src='/images/CrossRail-Tilt-Up.jpg'
                          className='k2_montare'
                        />
                        <ul className='u-align-left u-custom-font u-custom-list u-font-montserrat u-text u-text-default u-text-3'>
                          <li>
                            <div className='u-list-icon u-list-icon-1'>
                              <svg
                                className='u-svg-content'
                                viewBox='0 0 512.008 512.008'
                                id='svg-9e30'>
                                <path
                                  d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                                  fill='currentColor'
                                />
                              </svg>
                            </div>
                          </li>
                          <li>{t('k2_experienta')}</li>
                          <li>
                            <div className='u-list-icon u-list-icon-2'>
                              <svg
                                className='u-svg-content'
                                viewBox='0 0 512.008 512.008'
                                id='svg-9e30'>
                                <path
                                  d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                                  fill='currentColor'
                                />
                              </svg>
                            </div>
                            {t('k2_germana')}
                          </li>
                          <li>
                            <div className='u-list-icon u-list-icon-3'>
                              <svg
                                className='u-svg-content'
                                viewBox='0 0 512.008 512.008'
                                id='svg-9e30'>
                                <path
                                  d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                                  fill='currentColor'
                                />
                              </svg>
                            </div>
                            {t('k2_liderul')}
                          </li>
                          <li>
                            <div className='u-list-icon u-list-icon-4'>
                              <svg
                                className='u-svg-content'
                                viewBox='0 0 512.008 512.008'
                                id='svg-9e30'>
                                <path
                                  d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                                  fill='currentColor'
                                />
                              </svg>
                            </div>
                            {t('k2_solutii_profi')}
                          </li>
                          <li>
                            <div className='u-list-icon u-list-icon-5'>
                              <svg
                                className='u-svg-content'
                                viewBox='0 0 512.008 512.008'
                                id='svg-9e30'>
                                <path
                                  d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                                  fill='currentColor'
                                />
                              </svg>
                            </div>
                            {t('k2_garantie')}
                          </li>

                          <li>
                            <div className='u-list-icon u-list-icon-7'>
                              <svg
                                className='u-svg-content'
                                viewBox='0 0 512.008 512.008'
                                id='svg-9e30'>
                                <path
                                  d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                                  fill='currentColor'
                                />
                              </svg>
                            </div>
                            {t('k2_solutii_grele')}
                          </li>
                          <li>
                            <div className='u-list-icon u-list-icon-8'>
                              <svg
                                className='u-svg-content'
                                viewBox='0 0 512.008 512.008'
                                id='svg-9e30'>
                                <path
                                  d='m507.328 244.676-192-192c-4.608-4.576-11.456-5.92-17.44-3.456s-9.888 8.32-9.888 14.784v112h-272c-8.832 0-16 7.168-16 16v128c0 8.832 7.168 16 16 16h272v112c0 6.464 3.904 12.32 9.888 14.784 1.984.8 4.064 1.216 6.112 1.216 4.16 0 8.256-1.632 11.328-4.672l192-192c6.24-6.272 6.24-16.384 0-22.656z'
                                  fill='currentColor'
                                />
                              </svg>
                            </div>
                            {t('k2_materiale_inalte')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className='u-align-center u-container-style u-image u-layout-cell u-right-cell u-size-34 u-size-xs-60 u-image-2'
                    src
                    data-image-width={1800}
                    data-image-height={1600}>
                    <div
                      className='u-container-layout u-valign-top u-container-layout-2'
                      src
                    />
                  </div>
                </div>
              </div>
            </S.ResponsiveSlide>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Montare;
