import * as S from '../components/Asorty/styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
const Contacteaza = () => {
  const { t, i18n } = useTranslation(['translation']);

  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const options = {
    method: 'POST',
    url: 'https://rapidprod-sendgrid-v1.p.rapidapi.com/mail/send',
    headers: {
      'Content-Type': 'application/json',
      'X-RapidAPI-Key': 'b2265b0cd3mshc109dbcb240c0f5p1bfd14jsne1f4d12cac29',
      'X-RapidAPI-Host': 'rapidprod-sendgrid-v1.p.rapidapi.com',
    },
  };

  const sendEmail = () => {
    console.log('here');
    if (!fullName || !phoneNumber || !message) {
      toast.error(t('please_fill'));
    } else {
      axios
        .request({
          ...options,
          data: `{"personalizations":[{"to":[{"email":"grandsolarmd@gmail.com"}],"subject": "Message from grandsolarmd@gmail.com"}],"from":{"email":"grandsolarmd@gmail.com"},"content":[{"type":"text/plain","value":"Numar de telefon: ${phoneNumber} Nume: ${fullName} Mesaj: ${message}"}]}`,
        })
        .then((res) => {
          toast.success('Email sent!');
        })
        .catch((err) => toast.success('Email sent!'));
    }
  };

  return (
    <section
      className='u-align-center u-clearfix u-image u-shading u-section-12'
      id='sec-d788'
      data-image-width={3500}
      data-image-height={1851}>
      <div
        id='contactați-ne'
        style={{ position: 'absolute', top: '250px' }}></div>

      <div className='u-clearfix u-sheet u-valign-middle u-sheet-1'>
        <h2 className='u-custom-font u-font-montserrat u-text u-text-default u-text-1'>
          {t('contact-us-link')}
        </h2>
        <div className='u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1'>
          <div className='u-gutter-0 u-layout'>
            <div className='u-layout-row'>
              <div className='u-size-60'>
                <div className='u-layout-col'>
                  <div className='u-size-60'>
                    <div className='u-layout-row'>
                      <div
                        className='u-align-left u-container-style u-layout-cell u-left-cell u-size-25 u-layout-cell-1'
                        data-animation-name='customAnimationIn'
                        data-animation-duration={1000}>
                        <div className='u-container-layout u-container-layout-1 my_container'>
                          <h3 className='u-custom-font u-font-montserrat u-text u-text-2 newh3'>
                            {t('asigura_viitorul')}
                            <br />
                            <br />
                            {t('apeleaza_consultare')}
                          </h3>

                          <S.InfoCard
                            onClick={() => window.open('tel:+37367679999')}>
                            <div>
                              <p>{t('numarul_de_telefon')}</p>
                              <p>+37367679999</p>
                            </div>
                            <img src='images/phone.png' />
                          </S.InfoCard>
                          <S.InfoCard
                            onClick={() =>
                              window.open('mailto:grandsolarmd@gmail.com')
                            }>
                            <div>
                              <p>{t('email')}</p>
                              <p>grandsolarmd@gmail.com</p>
                            </div>
                            <img src='images/mail.png' />
                          </S.InfoCard>
                        </div>
                      </div>
                      <div
                        className='u-container-style u-layout-cell u-size-35 u-layout-cell-2'
                        data-animation-name='customAnimationIn'
                        data-animation-duration={1000}>
                        <div className='u-container-layout u-container-layout-2'>
                          <div className='u-form u-form-1'>
                            <form
                              onSubmit={() => sendEmail()}
                              className='u-clearfix u-form-spacing-10 u-form-vertical u-inner-form'
                              style={{ padding: '10px' }}>
                              <div className='u-form-group u-form-name u-label-none u-form-group-1'>
                                <label htmlFor='name-5359' className='u-label'>
                                  {t('nume_form')}
                                </label>
                                <input
                                  type='text'
                                  placeholder={t('nume_form')}
                                  id='name-5359'
                                  name='name'
                                  className='u-border-1 u-border-grey-30 u-input u-input-rectangle'
                                  required
                                  value={fullName}
                                  onChange={(e) => setFullName(e.target.value)}
                                />
                              </div>
                              <div className='u-form-email u-form-group u-label-none u-form-group-2'>
                                <label htmlFor='email-5359' className='u-label'>
                                  {t('nr_de_telefon')}
                                </label>
                                <input
                                  placeholder={t('nr_de_telefon')}
                                  id='email-5359'
                                  name='email'
                                  className='u-border-1 u-border-grey-30 u-input u-input-rectangle'
                                  required
                                  value={phoneNumber}
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                />
                              </div>
                              <div className='u-form-group u-form-message u-label-none u-form-group-3'>
                                <label
                                  htmlFor='message-5359'
                                  className='u-label'>
                                  {t('message')}
                                </label>
                                <textarea
                                  placeholder={t('message')}
                                  rows={4}
                                  cols={50}
                                  id='message-5359'
                                  name='message'
                                  className='u-border-1 u-border-grey-30 u-input u-input-rectangle'
                                  required
                                  defaultValue={''}
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                />
                              </div>
                            </form>
                            <button
                              className='u-border-none u-btn u-button-style u-custom-color-5 u-btn-1'
                              onClick={(e) => {
                                e.stopPropagation();
                                sendEmail();
                              }}>
                              {t('trimiteti')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Contacteaza;
